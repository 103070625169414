import {
  UrqBox,
  UrqButton,
  UrqColumn,
  UrqDrawer,
  UrqIcon,
  UrqImage,
  UrqInput,
  UrqRow,
  UrqText,
} from '../../../components/basecomponents'
import './Contact.css'
import EmailError from '../../../components/EmailError'
import EmailSuccess from '../../../components/EmailSuccess/EmailSuccess'

import ContactSvg from '../../../img/illustrations/email-01.svg'
import ContactTexture from '../../../img/textures/texture-03.jpg'

import CircularProgress from '@mui/material/CircularProgress'

import { useTranslation } from 'react-i18next'
import {
  urqTheme,
  useMuiTheme,
  useSmallScreen,
} from '../../../utils/tools'
import { postSendEmail } from '../../../utils/apicalls'
import type { EmailRequestType } from '../../../utils/apicalls'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Hero: React.FC = () => {
  const { t, i18n } = useTranslation()
  const muiTheme = useMuiTheme()
  const smallScreen = useSmallScreen()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const [mailError, setMailError] = useState('')

  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [messageError, setMessageError] = useState('')
  const navigate = useNavigate()
  const sendMailRequest: EmailRequestType = {
    name,
    email,
    message,
  }

  const handleToggleDrawer = () => {
    setIsOpenDrawer((isOpen) => !isOpen)
  }

  const validateName = () => {
    if (name.trim() === '') {
      setNameError('Ingrese un nombre válido')
    } else {
      setNameError('')
    }
  }

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (!emailRegex.test(email)) {
      setEmailError('Ingrese un correo electrónico válido')
    } else {
      setEmailError('')
    }
  }

  const validateMessage = () => {
    if (message.trim() === '') {
      setMessageError('Ingrese un mensaje válido');
    } else {
      setMessageError('');
    }
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }


  const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value)
  }


  const handleSendEmail = () => {
    setLoading(true)
    postSendEmail(sendMailRequest)
    .then((responseData) => {
      console.log('Respuesta:', responseData);
      setLoading(false)
      setIsOpenDrawer(true)
      setName('')
      setEmail('')
      setMessage('')
    })
    .catch((error) => {
      setMailError(error.message)
      setIsOpenDrawer(true)
      console.error('Error:', error);
      setLoading(false)
    });    
  }

  return (
    <UrqBox
      component='section'
      sx={{
        padding: smallScreen ? urqTheme.spaces.md1 : urqTheme.spaces.md2
      }}      
    >
      <UrqRow
        justifyContent='center'      
      >
        <UrqColumn
          xl={4}
          lg={5}
          md={6}
          sm={8}
          xs={12}                 
        >
          <UrqText
            variant='h1'
            className='urq-mt-24' 
          >
            {t('contact.title')}
          </UrqText>
          <UrqText
            className='contact-y-spacing'
            variant='body1'
          >
            {t('contact.description_01')}
          </UrqText>
          <form>
              <UrqInput
                label={t('contact.full_name.label')}
                placeholder={t('contact.full_name.placeholder')}
                value={name}
                onChange={handleChangeName}
                onBlur={validateName}
                error={!!nameError}
                helperText={nameError}
                disabled={loading}
              />
              <UrqInput
                label={t('contact.email.label')}
                placeholder={t('contact.email.placeholder')}
                value={email}
                onChange={handleChangeEmail}
                onBlur={validateEmail}
                error={!!emailError}
                helperText={emailError}
                disabled={loading}                                      
              />
              <UrqInput
                urqTextarea
                rows={8}
                label={t('contact.message.label')}
                placeholder={t('contact.message.placeholder')}
                value={message}
                onChange={handleChangeMessage}
                onBlur={validateMessage}
                error={!!messageError}
                helperText={messageError}
                disabled={loading}                   
              />
              <UrqButton
                className='contact-button'
                onClick={() => handleSendEmail()}
                disabled={Boolean(name === '' || email === '' || message === '' || nameError || emailError || messageError || loading)}
              >
                {
                  loading ?
                  <CircularProgress
                    color='inherit'
                  /> :
                  <>
                    {t('contact.send')}                 
                    <UrqIcon
                      urqIconName='Send'
                      sx={{
                        marginLeft: urqTheme.spaces.md2,
                        color: 'inherit',
                      }}
                    />
                  </>

                }
              </UrqButton>
          </form>   
        </UrqColumn>
        <UrqColumn
          className='
            urq-flex
            urq-flex-column
            urq-align-center
            urq-justify-center      
          '
          sx={{
            padding: smallScreen ? urqTheme.spaces.md1 : urqTheme.spaces.md2,
          }}           
          xl={4}
          lg={5}
          md={6}
          sm={8}
          xs={12}          
        >
          <UrqBox
            className='
              contact-email-svg-container
              urq-mt-64
            '
            sx={{
              background: urqTheme.colors.gradients.dark,
            }}            
          >
            {/* <UrqImage
              className='contact-email-bg'
              src={ContactTexture}
            /> */}
            <UrqBox
              className='contact-email-rectangle'
              sx={{
                background: muiTheme.palette.mode === 'dark' ? urqTheme.colors.brand.secondary[900] : urqTheme.colors.brand.secondary[500],
              }}             
            />            
            <UrqBox
              className='contact-email-border'
              sx={{
                border: `1.2px solid ${urqTheme.colors.brand.primary[600]}`,
              }}              
            />
            <UrqImage
              className='contact-email-svg'
              src={ContactSvg}
   
            />
          </UrqBox>
          <UrqBox
            className='contact-y-spacing'
            sx={{
              paddingLeft: `${!smallScreen ? urqTheme.spaces.lg : ''} `              
            }}
          >
            <UrqText
              variant='body1'
            >
              {t('contact.description_02')}
            </UrqText>
            <UrqText
              className='
                urq-flex
                urq-align-center
                contact-urqu-email
              '
            >
              <UrqIcon
                urqIconName='Mail'
                className='urq-mr-16'
              />
              {t('contact.urqu_email')}
            </UrqText>
          </UrqBox>
        </UrqColumn>
      </UrqRow>
      <UrqDrawer
        open={isOpenDrawer}
        urqClose={() => handleToggleDrawer()}
      >
        {
          mailError ?
          <EmailError
            messageError={mailError}
            onClose={() => handleToggleDrawer()}
          /> :
          <EmailSuccess />
        }
      </UrqDrawer>
    </UrqBox>
  );
}

export default Hero