import {
  UrqBox,
  UrqButton,
  UrqCard,
  UrqColumn,
  UrqDrawer,
  UrqIcon,
  UrqImage,
  UrqRow,
  UrqText
} from '../../../../components/basecomponents'
import { urqTheme, useMediumScreen, useMuiTheme, useSmallScreen } from '../../../../utils/tools'
import './ServiceCard.css'
import i18n from '../../../../i18n'
import { useTranslation } from 'react-i18next'

export type ServiceCardProps = {
  img: ImgType
  name: string
  position: React.ReactNode
  description: React.ReactNode
  isExpanded: boolean
  onClick: () => void
}

type ImgType = {
  url: string,
  alt: string,
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  img,
  name,
  position,
  description,
  isExpanded,
  onClick,
}) => {

  const smallScreen = useSmallScreen()
  const mediumScreen = useMediumScreen()
  const { t } = useTranslation()

  const ShowMore = () => { 
    return (
      <UrqButton
        variant='text'
      >
        <UrqText variant='button'>
          {
            t('common.show_more')
          }
        </UrqText>
      </UrqButton>
    )
  }
  
  return (
    <>
      <UrqCard
        variant='elevation'
        className='service-card-card'
        urqActions={<ShowMore />}
        onClick={onClick}
      >
        <UrqBox
          className='service-card-img-container'
          sx={{
            background: urqTheme.colors.gradients.dark,
          }}
        >
          <UrqImage
            src={img.url}
            alt={img.alt}
            className='service-card-img'
          />
        </UrqBox>
        <UrqText
          className='
            urq-text-align-center
            urq-mt-12
            urq-n-ml-10
            urq-mb-12
          '
          variant='h6'
        >
          {name}
        </UrqText>
        <UrqText
          className='urq-text-align-center'
          variant='subtitle2'
        >
          {position}
        </UrqText>
      </UrqCard>
      <UrqDrawer
        open={isExpanded}
        anchor={mediumScreen ? 'right' : 'left'}
        urqClose={onClick}
        sx={{
        }}
        >
        <UrqBox
          className='service-card-drawer-container'
          sx={{
            padding: smallScreen ? urqTheme.spaces.md1 : urqTheme.spaces.xl
          }}  
        >
          <UrqRow>
            <UrqColumn
              className='service-card-drawer-column'
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}              
            >
              <UrqBox
                className='
                  service-card-img-container
                  service-card-drawer-img-container
                '
                sx={{
                  background: urqTheme.colors.gradients.dark
                }}
              >
                <UrqImage
                  src={img.url}
                  alt={img.alt}
                  className='service-card-img'
                />
              </UrqBox>
              <UrqText
                className='
                  urq-text-align-center
                  urq-mt-12
                  urq-n-ml-10
                  urq-mb-12
                '
                variant='h6'
              >
                {name}
              </UrqText>
              {description}
              <UrqButton
                className='urq-mt-32'
                variant='text'
                onClick={onClick}
                sx={{
                  display: 'flex',
                  alignSelf: 'center',
                  width: 'auto',
                
                }}
              >
                <UrqText variant='button'>
                  {
                    t('common.go_back')
                  }
                </UrqText>
              </UrqButton>
            </UrqColumn>
          </UrqRow>
        </UrqBox>
      </UrqDrawer>
    </>
  );
};

export default ServiceCard;
