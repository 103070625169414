import UrqButton from './components/basecomponents/UrqButton';
import UrqBox from './components/basecomponents/UrqBox';
import UrqDrawer from './components/basecomponents/UrqDrawer';
import UrqCard from './components/basecomponents/UrqCard';
import UrqText from './components/basecomponents/UrqText';
import UrqAvatar from './components/basecomponents/UrqAvatar';
import UrqChip from './components/basecomponents/UrqChip';
import { useEffect, useState } from 'react';
import UrqIcon from './components/basecomponents/UrqIcon';
import UrqLink from './components/basecomponents/UrqLink';
import UrqInput from './components/basecomponents/UrqInput';
import UrqSnackbar from './components/basecomponents/UrqSnackbar';
import { useTheme } from '@mui/material/styles';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Main from './layout/Main';
import { Outlet } from 'react-router-dom';


function App() {
  const theme = useTheme()
  const [isDark, setIsDark] = useState(false)
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const toggleDarkMode = () => {
    setIsDark(() => !isDark)
  }
  
  useEffect(() => {
    if (isDark) {
      theme.palette.mode = 'dark'
    }
  }, [isDark])
  
  


  return (
    <UrqBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
    >
      <Header/>
      <Outlet />
      <Footer />    
    </UrqBox>
  );
}

export default App;
