import { CardContent, Collapse, CollapseProps as MuiCollapseProps } from '@mui/material'
import UrqText from '../UrqText/UrqText';

export type UrqCollapseProps = {
  children?: React.ReactNode
} & MuiCollapseProps

const UrqCollapse: React.FC<UrqCollapseProps> = ({
  children,
  ...rest
}) => {
  // Puedes acceder a las props adicionales de UrqCollapse y las props de Collapse de Material UI
  // Además, puedes acceder a onClick, onBlur y otros atributos de React directamente en rest

  return (
  <Collapse {...rest}>
    <CardContent>
      {children}
    </CardContent>
  </Collapse>    
  );
};

export default UrqCollapse;
