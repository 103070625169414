import axios from 'axios'

export type EmailRequestType = {
  name: string,
  email: string,
  message: string,

}

export const postSendEmail =  async (data: EmailRequestType): Promise<any> => { // falta agregar type de la response...
  const urlSendMail = 'https://kio4mestk6.execute-api.us-west-2.amazonaws.com/dev/mail'

  try {
    const response = await axios.post(urlSendMail, data);
    console.log('response en el post', response);
    return response.data;
  } catch (error: any) { // falta agregar type del error...
    console.log({ error })
    throw new Error(`Error al realizar la solicitud POST: ${error.message}`);
  }
}

