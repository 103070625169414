import {
  Tabs,
  TabsProps as MuiTabsProps
} from '@mui/material'

export type UrqTabsProps = {
  children: React.ReactNode
} & MuiTabsProps

const UrqTabs: React.FC<UrqTabsProps> = ({
  children,
  ...rest
}) => {
  // Puedes acceder a las props adicionales de UrqTabs y las props de Tabs de Material UI
  // Además, puedes acceder a onClick, onBlur y otros atributos de React directamente en rest

  return (
    <Tabs {...rest}>
      {children}
    </Tabs>
  );
};

export default UrqTabs;

