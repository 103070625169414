import { useState } from "react"
import {
  UrqBox,
  UrqButton,
  UrqCard,
  UrqImage,
  UrqList,
  UrqListItem,
  UrqText
} from "../../../../components/basecomponents"
import { urqTheme } from "../../../../utils/tools"
import './BulletCard.css'

export type BulletCardProps = {
  img?: ImgType
  icon?: string
  isList?: boolean
  title: string
  description: string | string[]
}

type ImgType = {
  url: string,
  alt: string,
}

const BulletCard: React.FC<BulletCardProps> = ({
  img,
  icon,
  title,
  description,
}) => {
  const [expanded, setExpanded] = useState(false)

  const handleToggle = () => {
    setExpanded(!expanded)
  }
  
  return (
    <UrqCard
      variant='elevation'
      className='bullet-card'
      style={{
        height: expanded ? 'auto' : '230px'
      }}
      urqHeader={{
        title:
          <UrqBox
            className={`
              bullet-card-img-container
              ${icon ? 'urq-flex urq-justify-center urq-align-center' : ''}
            `}
            sx={{
              background: urqTheme.colors.gradients.default,
            }}
          >
            {
              img &&
                <UrqImage
                  src={img.url}
                  alt={img.alt}
                  className='bullet-card-img'
                />
            }
            {
              icon &&
                <UrqText
                  variant='subtitle1'
                  className='bullet-card-img'
                  sx={{
                    fontSize: '3rem',
                    textAlign: 'center !important',
                    textShadow: `${urqTheme.colors.n400} 1px 0 30px`
                  }}
                >
                  {icon}
                </UrqText>
            }
          </UrqBox>,
      }}
      urqCollapse={{
        isExpanded: expanded,
        handle: () => handleToggle(),
        content:
          <UrqBox>
            <UrqList>
              {
                Array.isArray(description) ?
                description.map( (item: string, index: number) => (
                  <UrqListItem
                    key={index}
                    urqType='text'
                    className='urq-mb-12'
                  >
                    ⭐ {item}
                  </UrqListItem>
                ))
                :
                <UrqText variant='body1'>⭐ {description}</UrqText>        
              }
            </UrqList>
          </UrqBox>
      }}
      onClick={() => handleToggle()}
    >
      <UrqText
        className='
          urq-flex
          urq-justify-center
          urq-text-align-center
          urq-n-ml-10
        '
        variant='h6'
      >
        {title}
      </UrqText>
    </UrqCard>
  );
};

export default BulletCard;
