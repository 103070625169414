import { useTranslation } from 'react-i18next';
import { UrqLink, UrqText } from '../../components/basecomponents';
import { useMuiTheme } from '../../utils/tools';
import { useEffect, useState } from 'react';


// Componente de Footer
const Footer: React.FC = () => {
  const { i18n } = useTranslation()
  const tFooter= i18n.getFixedT(null, null, 'footer')
  const muiTheme = useMuiTheme()

  const [hasScroll, setHasScroll] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      const { scrollHeight, clientHeight } = document.documentElement;
      const hasScroll = scrollHeight > clientHeight;
      setHasScroll(hasScroll);
    };
  
    window.addEventListener('resize', checkScroll);
    checkScroll();
  
    return () => {
      window.removeEventListener('resize', checkScroll);
    };
  }, []);

  return (
    <footer
      className='
        urq-flex
        urq-justify-center
        urq-align-end
        urq-width-complete
      '
      style={{
        height: '100px',
        backgroundColor: muiTheme.palette.background.paper,
        padding: muiTheme.spacing(4),
        position: undefined,
        bottom: 0,
      }}
    >
      <UrqText
        variant='body2'
        color='textSecondary'
        align='center'
      >
        <UrqLink
          to='/privacy-policy'
          rel='noopener'
          color='inherit'
        >
          { tFooter('privacy_policy')}
        </UrqLink>
        <span className='urq-px-8'>|</span>
        <UrqLink
          to='/terms-and-conditions'
          // rel='noopener'
          color='inherit'
        >
          { tFooter('terms_and_conditions')}
        </UrqLink>        
      </UrqText>
    </footer>
  );
};

export default Footer;
