import { Drawer, DrawerProps as MuiDrawerProps } from '@mui/material'
import { theme as globalTheme } from '../../../themes/theme'
import { Padding } from '@mui/icons-material';
import UrqBox from '../UrqBox/UrqBox';
import UrqButton from '../UrqButton/UrqButton';
import { useSmallScreen } from '../../../utils/tools';

export type UrqDrawerProps = {
  urqClose?: () => void
  children: React.ReactNode,
} & MuiDrawerProps

const UrqDrawer: React.FC<UrqDrawerProps> = ({
  urqClose,
  children,
  ...rest
}) => {
  // Puedes acceder a las props adicionales de UrqDrawer y las props de Drawer de Material UI
  // Además, puedes acceder a onClick, onBlur y otros atributos de React directamente en rest

  return (
    <Drawer
      anchor={ rest.anchor ? rest.anchor : 'right' }
      onClose={urqClose ? urqClose : rest.onClose}
      {...rest}
    >
      {
        urqClose &&
        <UrqBox
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end'
          }}
        >
          <UrqButton
            urqIcon='Close'
            onClick={urqClose}
            sx={{
              margin: ' 12px 16px',
            }}
          />  
      </UrqBox>          
      }
      {children}
    </Drawer>
  );
};

export default UrqDrawer;