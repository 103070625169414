import { CardMedia, CardMediaProps as MuiCardMediaProps } from '@mui/material'
import { HTMLAttributes, ImgHTMLAttributes } from 'react';

export type UrqImageProps = {
} & React.DetailedHTMLProps<
React.ImgHTMLAttributes<HTMLImageElement>,
HTMLImageElement>

const UrqImage: React.FC<UrqImageProps> = ({
  ...rest
})  => {
  // Puedes acceder a las props adicionales de UrqImage y las props de CardMedia de Material UI
  // Además, puedes acceder a onClick, onBlur y otros atributos de React directamente en rest

  return (
    <img
      {...rest}
    />
  );
};

export default UrqImage

