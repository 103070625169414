import { TextField, TextFieldProps as MuiTextFieldProps } from '@mui/material'

export type UrqInputProps = {
  urqTextarea?: boolean
} & MuiTextFieldProps

const UrqInput: React.FC<UrqInputProps> = ({
  urqTextarea,
  ...rest
}) => {
  // Puedes acceder a las props adicionales de UrqInput y las props de Input de Material UI
  // Además, puedes acceder a onClick, onBlur y otros atributos de React directamente en rest

  return (
    <TextField
      multiline={urqTextarea ? urqTextarea : rest?.multiline}
      margin={rest.margin ? rest.margin : 'normal'}
      fullWidth={rest.fullWidth ? rest.fullWidth : true}
      {...rest}
    />
  );
};

export default UrqInput;

