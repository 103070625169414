import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from '@mui/material/styles';
import { theme as globalTheme} from './theme';
import CssBaseline from '@mui/material/CssBaseline';

import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import React from 'react';

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    urqu: true;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    urqu: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    urqu: Palette['primary'];
  }

  interface PaletteOptions {
    urqu?: PaletteOptions['primary'];
  }
}

type ThemeProp = {
  children: React.ReactNode;
};

const theme = responsiveFontSizes(createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: globalTheme.colors.brand.primary[700],
      dark: globalTheme.colors.brand.primary[900],
      light: globalTheme.colors.brand.primary[400],
    },
    secondary: {
      main: globalTheme.colors.brand.secondary.default,
      dark: globalTheme.colors.brand.secondary[900],
      light: globalTheme.colors.brand.secondary[200],
    },
    urqu: {
      main: globalTheme.colors.brand.primary[700],
      dark: globalTheme.colors.brand.primary[900],
      light: globalTheme.colors.brand.primary[400],
    },
  },
  typography: {
    fontFamily: globalTheme.fontFamilies.text,
    h1: {
      fontFamily: globalTheme.fontFamilies.title,
      fontSize: '3.5rem'
    },
    h2: {
      fontFamily: globalTheme.fontFamilies.title,
      fontSize: '2.5rem',
    },
    h3: {
      fontFamily: globalTheme.fontFamilies.title,
    },
    h4: {
      fontFamily: globalTheme.fontFamilies.title,
    },
    h5: {
      fontFamily: globalTheme.fontFamilies.title,
    },
    h6: {
      fontFamily: globalTheme.fontFamilies.title,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },    
    MuiButton: {
      variants: [
        {
          props: { variant: 'text'},
          style: {
            color: globalTheme.colors.brand.primary[600],
          }
        },
      ],
      defaultProps: {
        variant: 'contained',
        size: 'large',
        style: {
          textTransform: 'inherit',
          boxShadow: "none",
          borderRadius: "24px",
          paddingRight: '18px',
          paddingLeft: '18px',
          fontFamily: globalTheme.fontFamilies.title,
          fontWeight: globalTheme.fontWeights.semiBold,
          fontSize: '1.1rem'
        },        
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 2,
        style: {
          textTransform: "none",
          // boxShadow: "none",
          // background: globalTheme.colors.n0,
          fontFamily: globalTheme.fontFamilies.title,
          fontWeight: globalTheme.fontWeights.extraBold,
          color: globalTheme.colors.n900,
        },
      },
    },
    MuiCard: {
      variants: [
        {
          props: { variant: 'urqu'},
          style: {
            border: `1px solid ${globalTheme.colors.brand.secondary[500]}`,
          }
        },
      ],
      defaultProps: {
        elevation: 2,
        variant: 'urqu',
        style: {
          display: 'flex', 
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: `${globalTheme.spaces.xs}`,
          borderRadius: '16px',
        },
      },
    },
    MuiCardActions: {
      defaultProps: {
        style: {
          display: 'flex', 
          justifyContent: 'flex-end',
          borderColor: globalTheme.colors.brandLight,
          textTransform: 'uppercase',
        },
      },
    },
    MuiCardContent: {
      defaultProps: {
        style: {
          textAlign: 'start'
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        htmlColor: globalTheme.colors.brand.primary[700],
      },
    },
    MuiTypography: {
      defaultProps: {
        style: {
          textAlign: 'start'
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        style: {
          borderColor: globalTheme.colors.brand.secondary[700],
        },
        color: 'urqu',
        size: 'small',
      },
    },
    MuiSnackbar: {
      defaultProps: {
        autoHideDuration: 5000,
      },
    },
  },
}))

export const ThemeConfig: React.FC<ThemeProp> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};