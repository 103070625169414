import {
  UrqNavbar,
  UrqBox,
  UrqImage,
  UrqText,
} from '../../components/basecomponents'

import { useTheme, useMediaQuery } from '@mui/material'
import { theme as globalTheme } from '../../themes/theme'
import UrqLogo from '../../urqu-logo.png'
import { useTranslation } from 'react-i18next'
import {
  homePath,
  aboutPath,
  contactPath,
  servicesPath,
} from '../../utils/tools'


const Header: React.FC = () => {
  const { t, i18n } = useTranslation()
  const tLink = i18n.getFixedT('en', null, 'header.links')
  const links = [
    { 
      label: t('header.links.home'),
      path: homePath,
    },
    { 
      label: t('header.links.about'),
      path: aboutPath,
    },
    { 
      label: t('header.links.services'),
      path: servicesPath,
    },
    { 
      label: t('header.links.contact'),
      path: contactPath,
    },     
  ]

  const languages = [
    {
      id: 'en',
      name: 'EN',
    },
    {
      id: 'es',
      name: 'ES',
    }
  ]

  const muiTheme = useTheme()
  const screen320 = useMediaQuery('(max-width:320px)')
  const screen750 = useMediaQuery('(max-width:750px)')

  const UrquTechLogo = 
    <UrqBox
      sx={{
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
      }}
    >
      <UrqImage
        src={UrqLogo}
        style={{
          maxHeight: '24px',
          borderRadius: '4px',
          marginRight: globalTheme.spaces.sm,
        }}
      ></UrqImage>
      {
        (!screen750) ?
        <UrqText
          sx={{                
            color: muiTheme.palette.mode === 'dark' ? globalTheme.colors.n100 : globalTheme.colors.n900,
          }}
        >
          <strong>URQU-TECH</strong>
        </UrqText> :
        <UrqBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <UrqText
            sx={{                
              color: muiTheme.palette.mode === 'dark' ? globalTheme.colors.n100 : globalTheme.colors.n900,
              fontSize: '10px'
            }}
          >
            <strong>URQU</strong>
          </UrqText>
          <UrqText
            sx={{                
              color: muiTheme.palette.mode === 'dark' ? globalTheme.colors.n100 : globalTheme.colors.n900,
              fontSize: '11px'
            }}
          >
            <strong>TECH</strong>
          </UrqText>      
        </UrqBox>
      }
    </UrqBox>   

  return (
    <UrqNavbar
      urqNavLinks={links}
      urqCompany={UrquTechLogo}
      urqLanguages={languages}
    />
  );
}

export default Header