import {
  ListItem,
  ListItemProps as MuiListItemProps,
  ListItemAvatar,
  ListItemAvatarProps as MuiListItemAvatarProps,
  ListItemButton,
  ListItemButtonProps as MuiListItemButtonProps,
  ListItemIcon,
  ListItemIconProps as MuiListItemIconProps,
  ListItemText,
  ListItemTextProps as MuiListItemTextProps,
  ListItemSecondaryAction,
  ListItemSecondaryActionProps as MuiListItemSecondaryActionProps,
} from '@mui/material';

type UrqType =
  | 'avatar'
  | 'button'
  | 'icon'
  | 'secondaryAction'
  | 'text'
  | 'item';

export type UrqListItemProps = {
  children: React.ReactNode;
  urqType?: UrqType;
} & (
  | ({ urqType: 'avatar' } & MuiListItemAvatarProps)
  | ({ urqType: 'button' } & MuiListItemButtonProps)
  | ({ urqType: 'icon' } & MuiListItemIconProps)
  | ({ urqType: 'secondaryAction' } & MuiListItemSecondaryActionProps)
  | ({ urqType: 'text' } & MuiListItemTextProps)
  | ({ urqType: 'item' } & MuiListItemProps)
);

const componentMappings: Record<UrqType, React.ComponentType<any>> = {
  avatar: ListItemAvatar,
  button: ListItemButton,
  icon: ListItemIcon,
  secondaryAction: ListItemSecondaryAction,
  text: ListItemText,
  item: ListItem,
};

const UrqListItem: React.FC<UrqListItemProps> = ({
  children,
  urqType = 'item',
  ...rest
}) => {
  const DynamicListItem = componentMappings[urqType];

  return (
    <DynamicListItem {...rest}>
      {children}
    </DynamicListItem>
  );
};

export default UrqListItem;
