import {
  UrqBox,
  UrqButton,
  UrqColumn,
  UrqImage,
  UrqRow,
  UrqText,
} from '../../../components/basecomponents'
import { useTranslation } from 'react-i18next'
import HeroUrquLogo from '../../../img/logo/urqu-tech-logo.svg'

import './Hero.css'
import {
  useMuiTheme,
  useMediumScreen,
  useLargeScreen,
  useXLargeScreen,
  urqTheme as globalTheme,
  contactPath,
} from '../../../utils/tools'

const Hero: React.FC = () => {
  const { t, i18n } = useTranslation()
  const muiTheme = useMuiTheme()
  const mediumScreen = useMediumScreen()
  const largeScreen = useLargeScreen()
  const xLargeScreen =  useXLargeScreen()

  return (
    <UrqBox
      id='hero'
      component='section'
      className='hero-container'
    >
      <UrqRow
        justifyContent='center'
        direction={'column-reverse'}
      >
        <UrqColumn
          className='
            urq-flex
            urq-justify-space-between
            urq-align-center
            urq-flex-column
          '
          xl={4}
          lg={6}
          md={5}
          sm={10}
          xs={12}
        >
          <UrqText
            variant='h1'
            className='
              urq-mr-24
              urq-ml-24
            '
          >
            {t('hero.title')}
          </UrqText>
          <UrqText
            variant='subtitle1'
            className='
              urq-mt-32
              urq-mr-24
              urq-ml-24
            '
          >
            {t('hero.description')}
          </UrqText>
          <UrqButton
            className='
              hero-button
              urq-button-size
            '
            href={contactPath}
          >
            {t('common.contact_us')}  
          </UrqButton>
        </UrqColumn>
        <UrqColumn
          xl={4}
          lg={5}
          md={5}
          sm={10}
          xs={12}
          className={`
            urq-flex
            urq-justify-center
            hero-right-column
            ${!xLargeScreen || !largeScreen ? 'urq-align-end' : 'urq-align-center'}
          `}
        >
            <UrqImage
              className='
                hero-logo-urqu
                hero-logo-size
              '
              src={HeroUrquLogo}
            />
        </UrqColumn>
      </UrqRow>      
    </UrqBox>
  );
}

export default Hero