import {
  UrqBox,
  UrqButton,
  UrqColumn,
  UrqRow,
  UrqText,
} from '../../../components/basecomponents'
import { useTranslation } from 'react-i18next'

import ServiceSvg01 from '../../../img/illustrations/devops-01.svg'
import ServiceSvg02 from '../../../img/illustrations/apps-01.svg'
import ServiceSvg03 from '../../../img/illustrations/frontend-01.svg'
import ServiceSvg04 from '../../../img/illustrations/backend-01.svg'
import ServiceSvg05 from '../../../img/illustrations/ux-01.svg'

import './Services.css'
import {
  urqTheme,
  useMuiTheme,
  useSmallScreen,
  useLargeScreen,
  useXLargeScreen,
  useMediumScreen,
  contactPath,
} from '../../../utils/tools'
import { useState } from 'react'
import ServiceCard from './ServiceCard/ServiceCard'

const About: React.FC = () => {
  const { t, i18n } = useTranslation()
  const xLargeScreen = useXLargeScreen()
  const largeScreen = useLargeScreen()
  const smallScreen = useSmallScreen()
  const mediumScreen = useMediumScreen()
  const muiTheme = useMuiTheme()
  const tServices = i18n.getFixedT(null, null, 'services')

  const [ services, setServicess ] = useState([
    {
      name: tServices('service_01.title'),
      position: <>
      <UrqText
        variant='body1'
        className='urq-text-align-center'
      >
        {tServices('service_01.bullets.item_01')}
      </UrqText>
      <UrqText
        variant='body1'
        className='urq-text-align-center'
      >
        {tServices('service_01.bullets.item_02')}
      </UrqText>
      <UrqText
        variant='body1'
        className='urq-text-align-center'
      >
        {tServices('service_01.bullets.item_03')}
      </UrqText>
    </>,
      description: <>
        <UrqText
          variant='body1'
          className='urq-my-24'
        >
          {tServices('service_01.description')}
        </UrqText>
      </>,      
      img: {
        url: ServiceSvg01,
        alt: ''
      },
      expanded: false,
    },
    {
      name: tServices('service_02.title'),
      position: <>
      <UrqText
        variant='body1'
        className='urq-text-align-center'
      >
        {tServices('service_02.bullets.item_01')}
      </UrqText>
      <UrqText
        variant='body1'
        className='urq-text-align-center'
      >
        {tServices('service_02.bullets.item_02')}
      </UrqText>
      <UrqText
        variant='body1'
        className='urq-text-align-center'
      >
        {tServices('service_02.bullets.item_03')}
      </UrqText>
    </>,
      description: <>
        <UrqText
          variant='body1'
          className='urq-my-24'
        >
          {tServices('service_02.description')}
        </UrqText>
      </>,
      img: {
        url: ServiceSvg02,
        alt: ''
      },
      expanded: false
    },
    {
      name: tServices('service_03.title'),
      position: <>
      <UrqText
        variant='body1'
        className='urq-text-align-center'
      >
        {tServices('service_03.bullets.item_01')}
      </UrqText>
      <UrqText
        variant='body1'
        className='urq-text-align-center'
      >
        {tServices('service_03.bullets.item_02')}
      </UrqText>
      <UrqText
        variant='body1'
        className='urq-text-align-center'
      >
        {tServices('service_03.bullets.item_03')}
      </UrqText>
    </>,
      description: <>
        <UrqText
          variant='body1'
          className='urq-my-24'
        >
          {tServices('service_03.description')}
        </UrqText>
      </>,
      img: {
        url: ServiceSvg03,
        alt: ''
      },
      expanded: false
    },
    {
      name: tServices('service_04.title'),
      position: <>
      <UrqText
        variant='body1'
        className='urq-text-align-center'
      >
        {tServices('service_04.bullets.item_01')}
      </UrqText>
      <UrqText
        variant='body1'
        className='urq-text-align-center'
      >
        {tServices('service_04.bullets.item_02')}
      </UrqText>
      <UrqText
        variant='body1'
        className='urq-text-align-center'
      >
        {tServices('service_04.bullets.item_03')}
      </UrqText>
    </>,
      description: <>
        <UrqText
          variant='body1'
          className='urq-my-24'
        >
          {tServices('service_04.description')}
        </UrqText>
      </>,
      img: {
        url: ServiceSvg04,
        alt: ''
      },
      expanded: false
    },
    {
      name: tServices('service_05.title'),
      position: <>
      <UrqText
        variant='body1'
        className='urq-text-align-center'
      >
        {tServices('service_05.bullets.item_01')}
      </UrqText>
      <UrqText
        variant='body1'
        className='urq-text-align-center'
      >
        {tServices('service_05.bullets.item_02')}
      </UrqText>
      <UrqText
        variant='body1'
        className='urq-text-align-center'
      >
        {tServices('service_05.bullets.item_03')}
      </UrqText>
    </>,
      description: <>
        <UrqText
          variant='body1'
          className='urq-my-24'
        >
          {tServices('service_05.description')}
        </UrqText>
      </>,
      img: {
        url: ServiceSvg05,
        alt: ''
      },
      expanded: false
    },   
  ])

  const handleExpandClick = (index: number) => {
    setServicess((prevServices) =>
      prevServices.map((member, i) =>
        i === index ? { ...member, expanded: !member.expanded } : member
      )
    );
  }

  return (
    <UrqBox
      component='section'
      className='our-team-container'
      sx={{
        padding: smallScreen ? urqTheme.spaces.md1 : urqTheme.spaces.md2
      }}      
    >
      {/* Title  */}
      <UrqRow
        justifyContent='center'
        className='urq-mt-8'
      >
        <UrqColumn
          xl={9}
          lg={10}
          md={12}
          sm={12}
          xs={12}
          className='
            urq-flex
            urq-flex-column
            urq-justify-center
          '
        >
          <UrqText
            variant='h1'
            className={'urq-mt-24'}
            sx={{
              textAlign: !xLargeScreen || !largeScreen ? 'center !important' : 'start !important'
            }}
          >
            { tServices('title') }
          </UrqText>
        </UrqColumn>
      </UrqRow>
      {/* Services  */}
      <UrqRow
        justifyContent='center'
        className={mediumScreen ? 'urq-mt-32' : 'urq-mt-64'}
      >
        <UrqColumn
          xl={10}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className='
            urq-flex
            urq-flex-column
            urq-justify-center
          '
        >
          <UrqText
            variant='body1'
          >
            { tServices('description') }
          </UrqText>
        </UrqColumn>
        <UrqColumn
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          gap={6}
          className='
            urq-flex
            urq-justify-center
            urq-flex-is-wrap
            urq-mt-64
          '
        >
          {
            services.map(({ name, position, description, img, expanded }, index) => (
                <ServiceCard
                  key={`member-${index}`}
                  img={img}
                  position={position}
                  name={name}
                  description={description}
                  isExpanded={expanded}
                  onClick={() => handleExpandClick(index)}
                />
            )) 
          }
          
        </UrqColumn>
        <UrqColumn
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={`
            urq-flex
            urq-justify-center
            urq-align-center
            ${!mediumScreen && 'urq-mt-48'}
          `           
          }
        >
          <UrqButton
            className='
              hero-button
              urq-button-size
            '
            href={contactPath}
          >
                {t('common.contact_us')}  
          </UrqButton>
        </UrqColumn>
      </UrqRow>
    </UrqBox>
  );
}

export default About