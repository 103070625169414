import { Grid, GridProps as MuiGridProps } from '@mui/material'

export type UrqRowProps = {
  children: React.ReactNode
} & MuiGridProps

const UrqRow: React.FC<UrqRowProps> = ({
  children,
  ...rest
}) => {
  // Puedes acceder a las props adicionales de UrqRow y las props de Grid de Material UI
  // Además, puedes acceder a onClick, onBlur y otros atributos de React directamente en rest

  return (
    <Grid
      container
      spacing={ rest.spacing ? rest.spacing : 2 }
      { ...rest }
    >
      {children}
    </Grid>
  );
};

export default UrqRow;