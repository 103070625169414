import { Grid, GridProps as MuiGridProps } from '@mui/material'

export type UrqColumnProps = {
  children: React.ReactNode
} & MuiGridProps

const UrqColumn: React.FC<UrqColumnProps> = ({
  children,
  ...rest
}) => {
  // Puedes acceder a las props adicionales de UrqColumn y las props de Grid de Material UI
  // Además, puedes acceder a onClick, onBlur y otros atributos de React directamente en rest

  return (
    <Grid item {...rest}>
      {children}
    </Grid>
  );
};

export default UrqColumn;