import {
  Tab,
  TabProps as MuiTabProps
} from '@mui/material'

export type UrqTabProps = {
  // Props here
} & MuiTabProps

const UrqTab: React.FC<UrqTabProps> = ({ ...rest }) => {
  // Puedes acceder a las props adicionales de UrqTab y las props de Tab de Material UI
  // Además, puedes acceder a onClick, onBlur y otros atributos de React directamente en rest

  return (
    <Tab {...rest} />
  );
};

export default UrqTab;

