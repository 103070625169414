import { Select, SelectProps as MuiSelectProps, MenuItem } from '@mui/material'

export type UrqColumnProps = {
  children: React.ReactNode
  urqOptions?: UrqOption[]
} & MuiSelectProps

export type UrqOption = {
  id: string
  name: string
}

const UrqColumn: React.FC<UrqColumnProps> = ({
  children,
  urqOptions,
  ...rest
}) => {
  // Puedes acceder a las props adicionales de UrqColumn y las props de Select de Material UI
  // Además, puedes acceder a onClick, onBlur y otros atributos de React directamente en rest

  return (
    <Select
      variant={rest.variant ? rest.variant : 'outlined'}
      size={rest.size ? rest.size : 'small'}
      {...rest}
    >
      {
        urqOptions &&
        urqOptions.map(option => (
          <MenuItem
            key={option.id}
            value={option.id}
          >
            {option.name}
          </MenuItem>          
        ))
      }
      {children}
    </Select>
  );
};

export default UrqColumn;