export const theme = {
  fontFamilies: {
    title: "'Poppins', sans-serif",
    text: "'Montserrat', sans-serif"
  },
  colors: {
    n0: '#FEFEFC',
    n100: '#FBF9F6',
    n200: '#E9E9E9',
    n300: '#BFBFBF',
    n400: '#7F7F7F',
    n500: '#2B2B2B',
    n600: '#241F1F',
    n700: '#1E1517',
    n800: '#180D10',
    n900: '#14080C',
    // brandDefault: 'linear-gradient(102.47deg, #97ffdb -5.34%, #076298 106.58%)',
    brandDefault: '#30d199',
    brand: {
      primary: {
        default: '#30d199',
        100: '#D5FCDF',
        200: '#ADFAC8',
        300: '#81F1B4',
        400: '#60E3A8',
        500: '#30d199',
        600: '#23B38F',
        700: '#189683',
        800: '#0F7973',
        900: '#096064',
      },
      secondary: {
        default: '#fa8f32',
        100: '#FEF1D5',
        200: '#FEDFAD',
        300: '#FDC983',
        400: '#FBB364',
        500: '#fa8f32',
        600: '#D76E24',
        700: '#B35019',
        800: '#90370F',
        900: '#772509',  
      }
    },
    brandLight: '#50d9af',
    brandDark: '#34c995',
    brandDark2: '#2C9E77',
    brandHover: 'linear-gradient(102.47deg, #1667D9 -5.34%, #F279F2 106.58%)',
    greenDefault: '#29CC74',
    greenLight: '#CCFFE3',
    redDefault: '#E07F4F',
    redLight: '#FFDFD9',
    gradients: {
      default: 'linear-gradient(102.47deg,#FEF1D5 -5.34%, #60E3A8 106.58%)',
      light: 'linear-gradient(102.47deg, #FEF1D5 -5.34%, #ADFAC8 106.58%)',
      dark: 'linear-gradient(102.47deg, #FEDFAD -5.34%, #096064 106.58%)',
    }
  },
  fontSizes: {
    xl1: '200px',
    xl2: '96px',
    lg1: '48px',
    lg2: '32px',
    md1: '24px',
    md2: '18px',
    sm1: '16px',
    sm2: '14px',
    xs: '12px',
  },
  fontWeights: {
    extraLight: '200',
    light: '300',
    regular: '400',
    medium: '500',
    semiBold: '600',
    extraBold: '800',
    black: '900'
  },
  iconSizes: {
    sm: '32px',
    xs: '24px',
  },
  spaces: {
    xl: '48px',
    lg: '32px',
    md2: '24px',    
    md1: '16px',
    sm: '8px',
    xs: '4px',
  },
  breakpoints: {
    lg: '1920px',
    md: '1024px',
    sm: '375px',
  },
}; 