import React, { FC } from 'react'
import * as Icons from '@mui/icons-material'
import { SvgIconProps as MuiSvgIconProps, SvgIcon } from '@mui/material'

export type UrqIconNames = keyof typeof Icons

export type UrqIconProps = {
  children?: React.ReactNode
  urqIconName: UrqIconNames
} & MuiSvgIconProps


const UrqIcon: React.ElementType<UrqIconProps> = ({ children, urqIconName, ...rest }) => {

   const Icon = Icons[urqIconName]

  return (
    <>
      <Icon {...rest} />
      {/* <SvgIcon {...rest}>{children}</SvgIcon> */}
    </>
  )
}

export default UrqIcon