import {
  UrqBox,
  UrqButton,
  UrqColumn,
  UrqIcon,
  UrqImage,
  UrqInput,
  UrqRow,
  UrqText,
} from '../basecomponents'
import './EmailSuccess.css'

import EmailSuccessSvg from '../../img/illustrations/email-success-01.svg'
import { useTranslation } from 'react-i18next'

import {
  urqTheme,
  useMuiTheme,
  useSmallScreen,
} from '../../utils/tools'

const EmailSuccess: React.FC = () => {
  const { t, i18n } = useTranslation()
  const smallScreen = useSmallScreen()
  const muiTheme = useMuiTheme()

  return (
    <UrqBox
      component='section'
      className='urq-pt-64'
      sx={{
        padding: smallScreen ? urqTheme.spaces.md1 : urqTheme.spaces.md2
      }}      
    >
      <UrqRow
        justifyContent='center'      
      >
        <UrqColumn
          columns={12}
          className='
            urq-flex
            urq-flex-column
            urq-align-center
          '              
        >
          <UrqBox
            className='email-svg-container'
            sx={{
              background: urqTheme.colors.brand.primary[100]
            }}
          >
            <UrqBox
              className='email-svg-border'
              sx={{
                border: `1.2px solid ${urqTheme.colors.brand.primary[600]}`,
              }}
            />
            <UrqBox className='email-svg-illustration-container'>
              <UrqImage
                className='email-svg-illustration'
                src={EmailSuccessSvg}
              />
              <UrqBox
                className='email-svg-illustration-bg'
              />
            </UrqBox>
            <UrqBox
              className='email-svg-circle'
            />
          </UrqBox>
          <UrqText
            variant='body1'
            className='urq-mt-32'
          >
            { t('email_success.description_01') }
          </UrqText>
          <UrqText
            variant='body1'
            className='
              urq-width-complete
            '
          >
            { t('email_success.description_02') }
          </UrqText>          
          <UrqButton
            className='
              urq-button-size
              urq-mt-24
            '
            href='/'
          >
            {t('common.go_main_page')}
          </UrqButton>  
        </UrqColumn>
      </UrqRow>
    </UrqBox>
  );
}

export default EmailSuccess
