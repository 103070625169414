import {
  UrqBox,
  UrqButton,
  UrqColumn,
  UrqImage,
  UrqRow,
  UrqText,
} from '../../../components/basecomponents'
import { useTranslation } from 'react-i18next'

import UrquLogoSvg from '../../../img/logo/urqu-tech-logo-05.svg'
import MountainImg01 from '../../../img/various/mountain-01.jpg'
import TechImg01 from '../../../img/various/tech-01.jpg'
import TechImg02 from '../../../img/various/tech-02.jpg'


import './About.css'
import {
  urqTheme,
  useMuiTheme,
  useSmallScreen,
  useLargeScreen,
  useXLargeScreen,
  useMediumScreen,
  contactPath,
} from '../../../utils/tools'
import OurTeam from '../OurTeam/OurTeam'
import Bullets from '../Bullets'
// import Avatar from './Avatar/Avatar'

const About: React.FC = () => {
  const { t, i18n } = useTranslation()
  const xLargeScreen = useXLargeScreen()
  const largeScreen = useLargeScreen()
  const smallScreen = useSmallScreen()
  const mediumScreen = useMediumScreen()
  const muiTheme = useMuiTheme()

  return (
    <UrqBox
      component='section'
      className='our-team-container'
      sx={{
        padding: smallScreen ? urqTheme.spaces.md1 : urqTheme.spaces.md2
      }}      
    >
      {/* Title  */}
      <UrqRow
        justifyContent='center'
        className='urq-mt-8'
      >
        <UrqColumn
          xl={9}
          lg={10}
          md={12}
          sm={12}
          xs={12}
          className='
            urq-flex
            urq-flex-column
            urq-justify-center
          '
        >
          <UrqText
            variant='h1'
            className={`${!mediumScreen ? 'urq-mt-64' : 'urq-mt-24'}`}
            sx={{
              textAlign: !xLargeScreen || !largeScreen ? 'center !important' : 'start !important'
            }}
          >
            { t('about.section_01.title') }
          </UrqText>
        </UrqColumn>
      </UrqRow>
      {/* ROW 1  */}
      <UrqRow
        justifyContent='center'
        className={mediumScreen ? 'urq-mt-32' : 'urq-mt-64 '}
      >
        <UrqColumn
          xl={4}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`
            urq-flex
            urq-flex-column
            urq-justify-center
            urq-align-start
            ${mediumScreen && 'urq-my-64'}
          `
          }
        >
          <UrqBox
            className='
              about-logo-container
              about-logo-size
            '
          >
            <UrqImage
              className='about-logo-urqu'
              src={UrquLogoSvg}
            />
            <UrqImage
              className='
                about-logo-texture
                about-logo-size
              '
              style={{
                boxShadow: muiTheme.shadows[4],
              }}
              src={TechImg01}
            />       
            <UrqBox
              className='                
                about-logo-size
              '
            />
            <UrqBox
              className='
                about-logo-circle
                about-logo-light-circle-size
              '
              sx={{
                backgroundColor: muiTheme.palette.mode === 'dark' ? urqTheme.colors.brand.secondary[200] : urqTheme.colors.brand.secondary[900],
                opacity: muiTheme.palette.mode === 'dark' ? '0.1' : '16%',
              }}
            />          
          </UrqBox>          
        </UrqColumn>
        <UrqColumn
          xl={5}
          lg={4}
          md={6}
          sm={12}
          xs={12}
          className='
            urq-flex
            urq-flex-column
            urq-justify-center
          '
        >
          <UrqText
            variant='body1'
            className='
              urq-mt-16
            '
          >
            { t('about.section_01.description_01') }
          </UrqText>
          <UrqText
            variant='body1'
            className='
              urq-mt-16
            '
          >
            { t('about.section_01.description_02') }
          </UrqText>
          <UrqText
            variant='body1'
            className='
              urq-mt-16
            '
          >
            { t('about.section_01.description_03') }
          </UrqText> 
        </UrqColumn>
      </UrqRow> 
      <Bullets />
      {/* Title  */}
      <UrqRow
        justifyContent='center'
        className='urq-mt-64'
      >
        <UrqColumn
          xl={9}
          lg={10}
          md={12}
          sm={12}
          xs={12}
          className='
            urq-flex
            urq-flex-column
            urq-justify-center
          '
        >
          <UrqText
            variant='h1'
            className={`${!mediumScreen ? 'urq-mt-64' : 'urq-mt-24'}`}
            sx={{
              textAlign: !xLargeScreen || !largeScreen ? 'center !important' : 'start !important'
            }}
          >
            { t('about.section_02.title') }
          </UrqText>
        </UrqColumn>
      </UrqRow>
      {/* ROW 3  */}
      <UrqRow
        justifyContent='center'
        className='urq-mt-64 urq-mb-64'
      >
        <UrqColumn
          xl={5}
          lg={4}
          md={6}
          sm={12}
          xs={12}
          className='
            urq-flex
            urq-flex-column
            urq-justify-center
          '
        >
          <UrqText
            variant='body1'
            className='
              urq-mt-16
            '
          >
            { t('about.section_02.description_01') }
          </UrqText>
          <UrqText
            variant='body1'
            className='
              urq-mt-16
            '
          >
            { t('about.section_02.description_02') }
          </UrqText>
          <UrqText
            variant='body1'
            className='
              urq-mt-16
            '
          >
            { t('about.section_02.description_03') }
          </UrqText>         
        </UrqColumn>
        <UrqColumn
          xl={4}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={`
            urq-flex
            urq-flex-column
            urq-justify-center
            urq-align-end
            ${mediumScreen && 'urq-my-64'}
          `
          }
        >
          <UrqBox
            className='
              about-logo-container
              about-logo-size
            '
          >
            <UrqImage
              className='about-logo-urqu'
              src={UrquLogoSvg}
            />
            <UrqImage
              className='
                about-logo-texture
                about-logo-size
              '
              style={{
                boxShadow: muiTheme.shadows[4],
              }}
              src={MountainImg01}
            />       
            <UrqBox
              className='
                
                about-logo-size
              '
            />
            <UrqBox
              className='
                about-logo-circle
                about-logo-light-circle-size
              '
              sx={{
                backgroundColor: muiTheme.palette.mode === 'dark' ? urqTheme.colors.brand.secondary[200] : urqTheme.colors.brand.secondary[900],
                opacity: muiTheme.palette.mode === 'dark' ? '0.1' : '16%',
              }}
            />          
          </UrqBox>          
        </UrqColumn>
      </UrqRow>
      <OurTeam />
      <UrqRow
        justifyContent='center'
        className='urq-mt-64'
      >
        <UrqColumn
          xl={9}
          lg={10}
          md={12}
          sm={12}
          xs={12}
          className={`
            urq-flex
            urq-justify-center
            urq-align-center
            ${!mediumScreen && 'urq-mt-48'}
          `           
          }
        >
          <UrqButton
            className='
              hero-button
              urq-button-size
            '
            href={contactPath}
          >
                {t('common.contact_us')}  
          </UrqButton>
        </UrqColumn>
      </UrqRow>  
    </UrqBox>
  );
}

export default About