import {
  Card,
  CardContent,
  CardActions,
  CardProps as MuiCardProps,
  CardHeaderProps as MuiCardHeaderProps,
  CardHeader,
  useTheme
} from '@mui/material'
import UrqCollapse from '../UrqCollapse/UrqCollapse'

export type UrqCardProps = {
  children?: React.ReactNode
  urqCollapse?: {
    handle: () => void
    isExpanded: boolean
    content?: React.ReactNode
  }
  urqActions?: React.ReactNode
  urqHeader?: MuiCardHeaderProps
} & MuiCardProps

const UrqCard: React.FC<UrqCardProps> = ({
  children,
  // urqContent,
  urqActions,
  urqHeader,
  urqCollapse,
  ...rest
}) => {
  // Puedes acceder a las props adicionales de UrqCard y las props de Card de Material UI
  // Además, puedes acceder a onClick, onBlur y otros atributos de React directamente en rest
  return (
    <Card
      {...rest}
    >
      { urqHeader && (
        <CardHeader {...urqHeader} />
      )} 
      <CardContent>{children}</CardContent>
      { urqActions && (
        <CardActions>{urqActions}</CardActions>
      )}
      { urqCollapse && (
        <UrqCollapse
          in={urqCollapse.isExpanded}
          timeout='auto'
          unmountOnExit
        >
          <CardContent>
            {urqCollapse.content}
          </CardContent>
        </UrqCollapse>
      )}
    </Card>
  );
};

export default UrqCard;
