const translation = {
  header: {
    links: {
      home: 'Home',
      about: 'About us',
      contact: 'Contact',
      services: 'Services',
    }
  },
  hero: {
    title: 'Informatic solutions to your needs',
    description: 'We analyze, plan and materialize the best IT solution for your project.',
  },
  contact: {
    title: 'Send us a message!',
    description_01: "We'll answer you as soon as we can :).",
    description_02: "If you prefer, you can write us directly to our e-mail inbox:",
    urqu_email: 'contact@urqu.tech',
    full_name: {
      label: 'Full name',
      placeholder: 'Your name and lastname'
    },
    email: {
      label: 'E-mail',
      placeholder: 'email@example.com'
    },
    message: {
      label: 'Message',
      placeholder: 'Here goes your message',
    },
    send: 'Send message'
  },
  bullets: {
    title: 'Know how we work',
   item_01: {
      title: '🤓 Analysis',
      description: 'We thoroughly analyze what your IT project needs are and provide the best solutions to take it to the next level.',
    },
   item_02: {
      title: '🗓️ Planning',
      description: 'We create a work plan, using agile methodologies to provide flexibility and adaptability as we progress in development.',
    },
   item_03: {
      title: '👩‍💻 Materialization',
      description: 'We get down to work. Thanks to the analysis and planning, we can bring your dream project to life.',
    },        
  },
  // our_team: {
  //   title: 'Meet the URQU-TECH founders',
  //   description_01: 'At URQU, we strongly believe in our roots.',
  //   description_02: 'From our origin, surrounded by mountains, we strive to bring the best of what we have to offer to the world.',
  //   description_03: 'The combination of our experiences in various fields provides us with the opportunity to enhance our skills and deliver high-quality products that make a difference to our clients.',
  //   founder_01: {
  //     name: 'Ale Luna Bringas',
  //     position: 'DevOps, SRE Engineer',
  //     description: "Alejandro, an SRE (Site Reliability Engineer), brings the DevOps culture to every project he leads. With extensive experience in automation and Cloud technologies, he excels in crafting robust, secure, and automated architectures. His background as a Telecommunications Engineer allows him to deeply analyze security and networking needs, delivering strong and secure solutions both in Cloud and On-Premise environments.",
  //   },
  //   founder_02: {
  //     name: 'Pili Chanampe',
  //     position: 'Full Stack Developer',
  //     description: "Pili is a skilled Full-Stack developer with extensive experience in UX/UI and Front-End, currently diving into the Back-End world. Her meticulous and creative approach shines through in every project she works on. In addition to her technical expertise, she is also a visual artist and holds a degree in Music, specializing in Classical Guitar. Her artistic background provides her with a unique perspective, enabling her to offer innovative solutions and proposals that add genuine value to every product she develops.",
  //   },
  //   founder_03: {
  //     name: 'Juank Serrano',
  //     position: 'Sales Manager',
  //     description: 'Juank, with over a decade of experience leading companies across diverse industries, seamlessly combines his expertise as a Certified Public Accountant with his innovative and inquisitive mindset. He has successfully introduced agile methodologies to non-tech industries, bringing a fresh perspective to the business world. Now, he brings his extensive experience from other industries to the IT realm, offering quality, focus, and a well-defined vision.',
  //   },        
  // },
  about: {
    section_01: {
      title: '¿Qué es URQU-TECH?',
      description_01: 'Somos una empresa de desarrollo fundada y establecida en La Rioja, Argentina. Desde nuestro lugar, buscamos ofrecer soluciones robustas y escalables, con el foco siempre puesto en la sencillez en el uso de nuestros productos.',
      description_02: 'Para URQU, es importante pensar y reflexionar qué queremos lograr y resolver con cada propuesta. Nuestro punto de partida son las personas: queremos ayudarlas a concentrarse en lo que realmente necesitan, dándoles las soluciones que hagan más prácticas y amenas sus tareas.',
      description_03: 'Desde el momento del análisis, planificación y materialización de nuestros proyectos, la meta es crear productos pensados en otorgar experiencias simples, intuitivas y fáciles de usar.',
    },
    section_02: {
      title: '¿Cómo nació URQU-TECH?',
      description_01: 'Somos una empresa de desarrollo fundada y establecida en La Rioja, Argentina. Desde nuestro lugar, buscamos ofrecer soluciones robustas y escalables, con el foco siempre puesto en la sencillez en el uso de nuestros productos.',
      description_02: 'Para URQU, es importante pensar y reflexionar qué queremos lograr y resolver con cada propuesta. Nuestro punto de partida son las personas: queremos ayudarlas a concentrarse en lo que realmente necesitan, dándoles las soluciones que hagan más prácticas y amenas sus tareas.',
      description_03: 'Desde el momento del análisis, planificación y materialización de nuestros proyectos, la meta es crear productos pensados en otorgar experiencias simples, intuitivas y fáciles de usar.',
    },
    section_03: {
      title: 'Los fundadores',
      founder_01: {
        name: 'Ale Luna Bringas',
        position: 'SRE/DevOps Engineer',
        description: 'Ale es un SRE (Site Reliability Engineer) que infunde la cultura DevOps en todos los proyectos en los que participa. Posee una amplia experiencia automatizando infraestructura, lo que le ha permitido liderar diversos proyectos en la nube, creando arquitecturas robustas, seguras y escalables. Su formación como Ingeniero en Telecomunicaciones le otorga las herramientas necesarias para analizar a fondo las necesidades de seguridad y networking en cada solución.',
      },
      founder_02: {
        name: 'Pili Chanampe',
        position: 'Full Stack Developer',
        description: 'Pili es Licenciada en Música (especializada en Guitarra Clásica) y artista plástica. Su formación creativa, junto a su enfoque detallista, le otorgan una perspectiva única, permitiéndole ofrecer soluciones y propuestas innovadoras que añaden un valor genuino a cada producto que desarrolla. Posee un perfil Full-Stack con amplia experiencia en UX/UI y Front-End, optimizando el código para crear componentes y arquitecturas reutilizables, buscando generar experiencias cada vez más simples e intuitivas.',
      },
      founder_03: {
        name: 'Juank Serrano',
        position: 'Sales/Finance/Operations',
        description: 'Juank posee más de una década de experiencia en la dirección de empresas en diferentes industrias. Su formación como Contador Público, sumado a su perfil innovador y curioso, le permitieron desarrollar su potencial en cargos gerenciales. Ha llevado con éxito las metodologías ágiles a las plantas de producción, brindando una perspectiva fresca al mundo empresarial. Ahora trae toda su experiencia al ámbito de la tecnología, ofreciendo calidad, enfoque y una visión definida.',
      }, 
    },
  },
  services: {
    title: 'The services we offer',
    description: 'In our service offering, we provide all the necessary tools to deliver end-to-end solutions. We offer DevOps options for efficient technology project management. Additionally, we provide mobile and web application development services, both on the Front-End and Back-End. We also offer UX/UI services to create intuitive and appealing user experiences.',
    service_01: {
      title: 'DevOps',
      bullets: {
        item_01: 'MultiCloud',
        item_02: 'Serverless',
        item_03: 'Consultancy',
      },      
      description: 'We offer specialized DevOps consultancy to provide efficient solutions to our clients. Leveraging our expertise in DevOps architecture and methodologies, we design and optimize cloud-based technology infrastructures, including MultiCloud environments. Additionally, we implement Serverless solutions to enhance scalability and operational efficiency. Our DevOps services cover everything from deployment automation to configuration management and monitoring, ensuring an agile and robust software development and deployment lifecycle.',
    },
    service_02: {
      title: 'Aplications',
      bullets: {
        item_01: 'Android/iOs',
        item_02: 'PWA',
        item_03: 'Desktop',
      },
      description: 'We provide cross-platform application development solutions that cater to multiple platforms and devices. We offer native application development solutions for Android and iOS, ensuring seamless and optimized experiences on mobile devices. Additionally, we provide Progressive Web Apps (PWA) that allow users to access highly functional web applications from any browser. Moreover, we offer desktop application development services, delivering software solutions for Windows operating systems.',
    },
    service_03: {
      title: 'Front-End',
      bullets: {
        item_01: 'Web Design',
        item_02: 'CSR/SSR',
        item_03: 'Performance',
      },
      description: "We bring Front-End services that deliver high-quality web experiences. We specialize in web design, creating attractive and functional interfaces to captivate users' attention. We implement rendering techniques both on the client-side (CSR) and server-side (SSR) to ensure optimal performance and fast page loading. Our Front-End solutions focus on design, user experience, and efficient performance.",
    },
    service_04: {
      title: 'Back-End',
      bullets: {
        item_01: 'Architecture',
        item_02: 'Business logic',
        item_03: 'Integrations',
      },
      description: 'We offer robust and reliable Back-End services to meet the needs of our clients. We carefully design our architecture to ensure efficient and scalable performance. We handle the implementation of business logic in applications, ensuring accurate execution of specific algorithms and rules. Additionally, we offer integrations with external services, enabling connection and collaboration with third-party systems to extend functionality and enhance user experience. Our Back-End services serve as a solid foundation that drives the operation and success of your applications.',
    },
    service_05: {
      title: 'UX / UI',
      bullets: {
        item_01: 'Design',
        item_02: 'Accessibility',
        item_03: 'Mobile first',
      },
      description: 'We prioritize delivering exceptional user experiences. Through design, we create attractive and functional interfaces, considering factors such as usability and visual aesthetics. Additionally, we focus on accessibility, ensuring that our interfaces are inclusive and can be used by people with diverse abilities. We also embrace the "Mobile first" methodology, prioritizing the mobile experience to ensure a design that is adapted and optimized for smaller screens.',
    },
  },
  footer: {
    terms_and_conditions: 'Terms and Conditions',
    privacy_policy: 'Privacy Policy',
  },
  email_success: {
    description_01: 'Your e-mail was sent successfully!',
    description_02: "We'll get back to you very soon."
  },
  common: {
    contact_us: 'Contact us',
    go_main_page: 'Go to main page',
    go_back: 'Go back',
    close: 'Close',
    show_more: 'Show more',
    more: 'More',
  },
  error: {
    message: "Oops! It seems we're having problems with our e-mail service.",
    try_again: 'Please, try again later.'
  }
}

export default translation