import {
  List,
  ListProps as MuiListProps,
  ListSubheader,
  ListSubheaderProps
} from '@mui/material'

export type UrqListProps = {
  children: React.ReactNode
  urqListTitle?: {
    element: React.ReactNode
    props: ListSubheaderProps
  }
} & MuiListProps

const UrqList: React.FC<UrqListProps> = ({
  children,
  urqListTitle,
  ...rest
}) => {
  // Puedes acceder a las props adicionales de UrqList y las props de List de Material UI
  // Además, puedes acceder a onClick, onBlur y otros atributos de React directamente en rest

  return (
    <List
      {...rest}
      subheader={
        urqListTitle &&
        <ListSubheader
          {...urqListTitle.props}
        >
          { urqListTitle.element }
        </ListSubheader>
      }
    >
      {children}
    </List>
  );
};

export default UrqList;