import {
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { theme } from "../themes/theme"
import i18n from "../i18n"

export const getPathFromLabel = (str: string) => {
  const excludedCharsRegex = /[?¡!&$+,\/:;=?@#|<>^*()%!¡¿'"[\]{}\\]+/g

  const normalizedString = str.normalize("NFD").replace(/[\u0300-\u036f´]/g, "")
  
  const cleanString = normalizedString.replace(excludedCharsRegex, '')

  return cleanString.trim().replace(/\s+/g, '-').toLowerCase()
}

const tLink = i18n.getFixedT('en', null, 'header.links')
export const homePath = '/'
export const aboutPath = `/${getPathFromLabel(tLink('about'))}`
export const contactPath = `/${getPathFromLabel(tLink('contact'))}`
export const ourTeamPath = `/${getPathFromLabel(tLink('ourTeam'))}`
export const servicesPath = `/${getPathFromLabel(tLink('services'))}`


export const urqTheme = theme

export const useMuiTheme = () => useTheme()

export const useXSmallScreen = () => useMediaQuery(useMuiTheme().breakpoints.down('xs'))

export const useSmallScreen = () => useMediaQuery(useMuiTheme().breakpoints.down('sm'))

export const useMediumScreen = () => useMediaQuery(useMuiTheme().breakpoints.down('md'))

export const useLargeScreen = () => useMediaQuery(useMuiTheme().breakpoints.down('lg'))

export const useXLargeScreen = () => useMediaQuery(useMuiTheme().breakpoints.down('xl'))

