import { Box, BoxProps as MuiBoxProps } from '@mui/material'

export type UrqBoxProps = {
  children?: React.ReactNode
} & MuiBoxProps

const UrqBox: React.FC<UrqBoxProps> = ({
  children,
  ...rest
}) => {
  // Puedes acceder a las props adicionales de UrqBox y las props de Box de Material UI
  // Además, puedes acceder a onClick, onBlur y otros atributos de React directamente en rest

  return (
    <Box {...rest}>
      {children}
    </Box>
  );
};

export default UrqBox;