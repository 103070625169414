const translation = {
  header: {
    links: {
      home: 'Inicio',
      about: 'Nosotros',
      contact: 'Contacto',
      ourTeam: 'Nosotros',
      services: 'Servicios',
    }
  },
  hero: {
    title: 'Aplicaciones simples e intuitivas',
    description: 'Generamos experiencias fluidas y sin complicaciones para que puedas concentrarte en lo que realmente necesitás.',
  },
  contact: {
    title: '¡Mandanos un mensaje!',
    description_01: 'Te vamos a contestar lo más rápido posible :).',
    description_02: 'Si preferís, podés escribirnos directamente a nuestra casilla de e-mail:',
    urqu_email: 'contact@urqu.tech',
    full_name: {
      label: 'Nombre completo',
      placeholder: 'Tu nombre y apellido',
    },
    email: {
      label: 'E-mail',
      placeholder: 'email@ejemplo.com'
    },
    message: {
      label: 'Mensaje',
      placeholder: 'Acá escribí tu mensaje',
    },
    send: 'Enviar mensaje'
  },
  bullets: {
    title: 'Nuestra razón: misión, visión y valores',
    item_01: {
      title: 'Misión',
      // description: 'Analizamos en profundidad cuáles son las necesidades y las mejores soluciones  para llevar a tu proyecto IT al próximo nivel',
      description: 'Buscamos hacer del mundo un lugar más simple, creando y diseñando soluciones intuitivas e inclusivas que ayuden a la humanidad a dar el siguiente paso.',
    },
    item_02: {
      title: 'Visión',
      description: 'Queremos transformar la vida de nuestros clientes, brindándoles las herramientas necesarias para optimizar su tiempo y mejorar su rendimiento.',
    },
    item_03: {
      title: 'Valores',
      description: {
        bullet_01: 'Evolucionamos hacia la simpleza.',
        bullet_02: 'Crecemos juntos como equipo.',
        bullet_03: 'Visualizamos la meta antes de dar vida al código.',
        bullet_04: 'Construimos con enfoque intuitivo.',
        bullet_05: 'Compartimos y multiplicamos conocimiento.',
        bullet_06: 'Priorizamos a las personas.',
      }
    },        
  },
  // our_team: {
  //   title: 'Los fundadores',
  //   founder_01: {
  //     name: 'Ale Luna Bringas',
  //     position: 'SRE/DevOps Engineer',
  //     description: 'Ale es un SRE (Site Reliability Engineer) que infunde la cultura DevOps en todos los proyectos en los que participa. Posee una amplia experiencia automatizando infraestructura, lo que le ha permitido liderar diversos proyectos en la nube, creando arquitecturas robustas, seguras y escalables. Su formación como Ingeniero en Telecomunicaciones le otorga las herramientas necesarias para analizar a fondo las necesidades de seguridad y networking en cada solución.',
  //   },
  //   founder_02: {
  //     name: 'Pili Chanampe',
  //     position: 'Full Stack Developer',
  //     description: 'Pili es Licenciada en Música (especializada en Guitarra Clásica) y artista plástica. Su formación creativa, junto a su enfoque detallista, le otorgan una perspectiva única, permitiéndole ofrecer soluciones y propuestas innovadoras que añaden un valor genuino a cada producto que desarrolla. Posee un perfil Full-Stack con amplia experiencia en UX/UI y Front-End, optimizando el código para crear componentes y arquitecturas reutilizables, buscando generar experiencias cada vez más simples e intuitivas.',
  //   },
  //   founder_03: {
  //     name: 'Juank Serrano',
  //     position: 'Sales/Finance/Operations',
  //     description: 'Juank posee más de una década de experiencia en la dirección de empresas en diferentes industrias. Su formación como Contador Público, sumado a su perfil innovador y curioso, le permitieron desarrollar su potencial en cargos gerenciales. Ha llevado con éxito las metodologías ágiles a las plantas de producción, brindando una perspectiva fresca al mundo empresarial. Ahora trae toda su experiencia al ámbito de la tecnología, ofreciendo calidad, enfoque y una visión definida.',
  //   },        
  // },
  about: {
    section_01: {
      title: '¿Qué es URQU-TECH?',
      description_01: 'Somos una empresa de desarrollo fundada y establecida en La Rioja, Argentina. Desde nuestro lugar, buscamos ofrecer soluciones robustas y escalables, con el foco siempre puesto en la sencillez en el uso de nuestros productos.',
      description_02: 'Para URQU, es importante pensar y reflexionar qué queremos lograr y resolver con cada propuesta. Nuestro punto de partida son las personas: queremos ayudarlas a concentrarse en lo que realmente necesitan, dándoles las soluciones que hagan más prácticas y amenas sus tareas.',
      description_03: 'Desde el momento del análisis, planificación y materialización de nuestros proyectos, la meta es crear productos pensados en otorgar experiencias simples, intuitivas y fáciles de usar.',
    },
    section_02: {
      title: '¿Cómo nació URQU-TECH?',
      description_01: 'Conocer y reconocer de dónde venimos, nuestro origen, nos permite potenciar, proyectar y vislumbrar hacia a dónde queremos llegar. Desde que nacimos, nuestras vidas siempre mantuvieron algo en común: la montaña que nos acompaña desde el horizonte.',
      description_02: 'URQU es una palabra quechua (una de las lenguas que precedió al español en estas tierras) que significa montaña y, a su vez, TECH es la abreviatura de la palabra inglesa technology, lengua que nos acompaña día a día en nuestro trabajo.',
      description_03: 'Tomamos la figura de la montaña como representación de nuestro origen, de aquello que nos precede y nos otorga una visión del mundo desde nuestro lugar.',
      description_04: 'La combinación de nuestras experiencias en diversos campos, nos da la oportunidad de potenciar nuestras habilidades para ofrecer a nuestros clientes productos de calidad que marquen la diferencia.',
    },
    section_03: {
      title: 'Los fundadores',
      founder_01: {
        name: 'Ale Luna Bringas',
        position: 'SRE/DevOps Engineer',
        description: 'Ale es un SRE (Site Reliability Engineer) que infunde la cultura DevOps en todos los proyectos en los que participa. Posee una amplia experiencia automatizando infraestructura, lo que le ha permitido liderar diversos proyectos en la nube, creando arquitecturas robustas, seguras y escalables. Su formación como Ingeniero en Telecomunicaciones le otorga las herramientas necesarias para analizar a fondo las necesidades de seguridad y networking en cada solución.',
      },
      founder_02: {
        name: 'Pili Chanampe',
        position: 'Full Stack Developer',
        description: 'Pili es Licenciada en Música (especializada en Guitarra Clásica) y artista plástica. Su formación creativa, junto a su enfoque detallista, le otorgan una perspectiva única, permitiéndole ofrecer soluciones y propuestas innovadoras que añaden un valor genuino a cada producto que desarrolla. Posee un perfil Full-Stack con amplia experiencia en UX/UI y Front-End, optimizando el código para crear componentes y arquitecturas reutilizables, buscando generar experiencias cada vez más simples e intuitivas.',
      },
      founder_03: {
        name: 'Juank Serrano',
        position: 'Sales/Finance/Operations',
        description: 'Juank posee más de una década de experiencia en la dirección de empresas en diferentes industrias. Su formación como Contador Público, sumado a su perfil innovador y curioso, le permitieron desarrollar su potencial en cargos gerenciales. Ha llevado con éxito las metodologías ágiles a las plantas de producción, brindando una perspectiva fresca al mundo empresarial. Ahora trae toda su experiencia al ámbito de la tecnología, ofreciendo calidad, enfoque y una visión definida.',
      }, 
    },
  },
  services: {
    title: 'Los servicios que ofrecemos',
    description: 'En nuestra oferta de servicios, proporcionamos todas las herramientas necesarias para ofrecer soluciones integrales de principio a fin. Disponemos de opciones en DevOps para una gestión eficiente de proyectos tecnológicos. Además, ofrecemos desarrollo de aplicaciones móviles y web, tanto en el Front-End como en el Back-End. También ofrecemos servicios de UX/UI para crear experiencias intuitivas y atractivas para los usuarios.',
    service_01: {
      title: 'DevOps',
      bullets: {
        item_01: 'Consultoría',
        item_02: 'MultiCloud',
        item_03: 'Serverless',
      },      
      description: 'Ofrecemos consultoría DevOps especializados para brindar soluciones eficientes a nuestros clientes. A través de nuestra experiencia en arquitectura y metodologías DevOps, diseñamos y optimizamos infraestructuras tecnológicas basadas en la nube, incluyendo entornos MultiCloud. Además, implementamos soluciones Serverless para mejorar la escalabilidad y la eficiencia operativa. Nuestros servicios DevOps abarcan desde la automatización de despliegues hasta la gestión de la configuración y la monitorización, garantizando un ciclo de vida de desarrollo y despliegue de software ágil y robusto.',
    },
    service_02: {
      title: 'Aplicaciones',
      bullets: {
        item_01: 'Android/iOs',
        item_02: 'PWA',
        item_03: 'Desktop',
      },
      description: 'Proporcionamos desarrollos de aplicaciones que abarcan múltiples plataformas y dispositivos. Brindamos soluciones de desarrollo de aplicaciones nativas para Android e iOS, garantizando experiencias fluidas y optimizadas en dispositivos móviles. Además, ofrecemos Progressive Web Apps (PWA) que permiten a los usuarios acceder a aplicaciones web altamente funcionales desde cualquier navegador. También ofrecemos servicios de desarrollo de aplicaciones de escritorio, proporcionando soluciones de software para sistemas operativos Windows.',
    },
    service_03: {
      title: 'Front-End',
      bullets: {
        item_01: 'Diseño web',
        item_02: 'CSR/SSR',
        item_03: 'Performance',
      },
      description: 'Brindamos servicios Front-End que otorgan experiencias web de calidad. Nos especializamos en el diseño web, creando interfaces atractivas y funcionales para captar la atención de los usuarios. Implementamos técnicas de renderizado tanto del lado del cliente (CSR) como del lado del servidor (SSR) para asegurar un rendimiento óptimo y una carga rápida de las páginas. Nuestras soluciones FrontEnd se centran en el diseño, la experiencia del usuario y el rendimiento eficiente.',
    },
    service_04: {
      title: 'Back-End',
      bullets: {
        item_01: 'Arquitectura',
        item_02: 'Lógica de negocio',
        item_03: 'Integraciones',
      },
      description: 'Ofrecemos servicios Back-End sólidos y confiables para satisfacer las necesidades de nuestros clientes. Diseñamos cuidadosamente nuestra arquitectura para garantizar un rendimiento eficiente y escalable. Nos encargamos de implementar la lógica de negocio de las aplicaciones, asegurando la ejecución precisa de algoritmos y reglas específicas. Además, proporcionamos integraciones con servicios externos, permitiendo la conexión y colaboración con sistemas de terceros para ampliar las funcionalidades y mejorar la experiencia del usuario.',
    },
    service_05: {
      title: 'UX / UI',
      bullets: {
        item_01: 'Diseño',
        item_02: 'Accesibilidad',
        item_03: 'Mobile first',
      },
      description: 'Ponemos como prioridad brindar experiencias excepcionales a los usuarios. A través del diseño, creamos interfaces atractivas y funcionales, considerando factores como la usabilidad y la estética visual. Además, nos enfocamos en la accesibilidad, asegurándonos de que nuestras interfaces sean inclusivas y puedan ser utilizadas por personas con diversas capacidades. También adoptamos la metodología "Mobile first", priorizando la experiencia en dispositivos móviles para garantizar un diseño adaptado y optimizado para pantallas más pequeñas.',
    },
  },
  footer: {
    terms_and_conditions: 'Términos y condiciones',
    privacy_policy: 'Política de privacidad',
  },
  email_success: {
    description_01: "¡Tu e-mail se mandó correctamente!",
    description_02: 'Nos contactaremos muy pronto'
  },
  common: {
    contact_us: 'Contactanos',
    go_main_page: 'Ir a la página principal',
    go_back: 'Volver',
    close: 'Cerrar',
    show_more: 'Ver más',
    more: 'Más',
  },
  error: {
    message: "Ups! Parece que estamos teniendo problemas con nuestro servicio de mail.",    
    try_again: 'Por favor, intentá de nuevo más tarde.'
  }
}

export default translation