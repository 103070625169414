import {
  UrqBox,
  UrqButton,
  UrqColumn,
  UrqIcon,
  UrqImage,
  UrqInput,
  UrqRow,
  UrqText,
} from '../basecomponents'
import './EmailError.css'

import EmailErrorSvg from '../../img/illustrations/email-error-01.svg'
import { useTranslation } from 'react-i18next'

import {
  urqTheme,
  useMuiTheme,
  useSmallScreen,
} from '../../utils/tools'

export type MailErrorProps = {
  messageError: string,
  onClose: () => void
}

const EmailError: React.FC<MailErrorProps> = ({
  messageError,
  onClose,
 }) => {
  const { t, i18n } = useTranslation()
  const smallScreen = useSmallScreen()
  const muiTheme = useMuiTheme()

  return (
    <UrqBox
      component='section'
      className='urq-pt-64'
      sx={{
        padding: smallScreen ? urqTheme.spaces.xl : urqTheme.spaces.lg
      }}      
    >
      <UrqRow
        justifyContent='center'      
      >
        <UrqColumn
          columns={10}
          className='
            urq-flex
            urq-flex-column
            urq-align-center
          '    
        >
          <UrqBox
            className='email-error-svg-container'
            sx={{
              background: urqTheme.colors.brand.secondary[600]
            }}
          >
            <UrqBox
              className='email-error-svg-border'
              sx={{
                border: `1.2px solid ${urqTheme.colors.brand.primary[600]}`,
              }}
            />
            <UrqBox className='email-error-svg-illustration-container'>
              <UrqImage
                className='email-error-svg-illustration'
                src={EmailErrorSvg}
              />
            </UrqBox>
            <UrqBox
              className='email-error-svg-circle'
            />
          </UrqBox>
          <UrqText
            variant='body1'
            className='urq-mt-32'
          >
            { t('error.message') }
          </UrqText>
          <UrqText
            variant='body1'
            className='urq-mt-32'
          >
            { t('error.try_again') }
          </UrqText>          
          <UrqButton
            className='
              urq-button-size
              urq-mt-24
            '
            variant='outlined'
            onClick={onClose}
          >
            {t('common.close')}
          </UrqButton>  
        </UrqColumn>
      </UrqRow>
    </UrqBox>
  );
}

export default EmailError
