import {
  Toolbar,
  ToolbarProps as MuiToolbarProps,
  useTheme,
} from '@mui/material'

import { theme as globalTheme } from '../../../themes/theme'

export type UrqToolbarProps = {
  children?: React.ReactNode
} & MuiToolbarProps

const UrqToolbar: React.FC<UrqToolbarProps> = ({
  children,
  ...rest
}) => {

  const muiTheme = useTheme()

  return (
    <Toolbar
      // variant={rest.variant ? rest.variant : 'dense' }
      { ...rest }
    >{ children }</Toolbar>
  )
}

export default UrqToolbar