import {
  UrqBox,
  UrqColumn,
  UrqRow,
  UrqText,
} from '../../../components/basecomponents'
import { useTranslation } from 'react-i18next'

import './Bullets.css'
import {
  useMediumScreen,
  useLargeScreen,
  useXLargeScreen,
  useSmallScreen,
} from '../../../utils/tools'
import BulletCard from './BulletCard/BulletCard'

const Bullets: React.FC = () => {
  const { t } = useTranslation()
  const smallScreen = useSmallScreen()
  const mediumScreen = useMediumScreen()
  const largeScreen = useLargeScreen()
  const xLargeScreen =  useXLargeScreen()

  const cards = [
    {
      icon: '🚀',
      title: t('bullets.item_01.title'),
      description: t('bullets.item_01.description'),
    },
    {
      icon: '💡',
      title: t('bullets.item_02.title'),
      description: t('bullets.item_02.description'),
    },
    {
      icon: '💕',
      title: t('bullets.item_03.title'),
      description: [
        t('bullets.item_03.description.bullet_01'),
        t('bullets.item_03.description.bullet_02'),
        t('bullets.item_03.description.bullet_03'),
        t('bullets.item_03.description.bullet_04'),
        t('bullets.item_03.description.bullet_05'),
        t('bullets.item_03.description.bullet_06'),
      ],
      isList: true,
    },
  ]

  return (
    <UrqBox
      id='bullets'
      component='section'
      className='bullets-container'
      sx={{
        marginTop: mediumScreen ? '60px' : '96px',
      }}
    >
      <UrqRow
        justifyContent='center'
        className='urq-mt-8'
      >
        <UrqColumn
          xl={9}
          lg={10}
          md={12}
          sm={12}
          xs={12}
          className='
            urq-flex
            urq-flex-column
            urq-justify-center
          '
        >
          <UrqText
            variant='h1'
            sx={{
              textAlign: mediumScreen ? 'start !important' : 'center !important'
            }}
          >
            { t('bullets.title') }
          </UrqText>
        </UrqColumn>
      </UrqRow>      
      <UrqRow
        justifyContent='center'
        // direction={mediumScreen ? 'column-reverse' : 'row'}
      >
        <UrqColumn
          xl={8}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          gap={6}
          className='
            urq-flex
            urq-justify-center
            urq-flex-is-wrap
            urq-mt-64
          '
        >
          {
            cards.map(({ icon, title, description }, index) => (
              <BulletCard
                key={`${title}-${index}`}
                {...{
                  icon,
                  title,
                  description,
                }}
              ></BulletCard>
            ))
          }
        </UrqColumn>
      </UrqRow>
    </UrqBox>
  );
}

export default Bullets