import { Link, LinkProps as MuiLinkProps } from '@mui/material'
import { LinkProps as RouterLinkProps } from 'react-router-dom'

export type UrqLinkProps = {
  children: React.ReactNode
} & MuiLinkProps & RouterLinkProps

const CustomLink: React.ComponentType<UrqLinkProps> = Link as React.ComponentType<UrqLinkProps>


const UrqLink: React.FC<UrqLinkProps> = ({
  children,
  ...rest
}) => {
  // Puedes acceder a las props adicionales de UrqLink y las props de Link de Material UI
  // Además, puedes acceder a onClick, onBlur y otros atributos de React directamente en rest

  return (
    <CustomLink
      underline={rest.underline ? rest.underline : 'hover'}
      {...rest}
    >
      {children}
    </CustomLink>
  );
};

export default UrqLink;
