import { Typography, TypographyProps as MuiTypographyProps } from '@mui/material'

export type UrqTextProps = {
  children: React.ReactNode
} & MuiTypographyProps

const UrqText: React.FC<UrqTextProps> = ({
  children,
  ...rest
}) => {
  // Puedes acceder a las props adicionales de UrqText y las props de Typography de Material UI
  // Además, puedes acceder a onClick, onBlur y otros atributos de React directamente en rest

  return (
    <Typography {...rest}>
      {children}
    </Typography>
  );
};

export default UrqText;