// @ts-nocheck
import {
  AppBar,
  AppBarProps as MuiAppBarProps,
  useTheme,
  useMediaQuery,
} from '@mui/material'

import { useTranslation } from 'react-i18next'
// Si no lo importo de esta manera, me está tirando error.
import UrqTab from '../UrqTab'

import {
  UrqToolbar,
  UrqDrawer,
  UrqList,
  UrqListItem,
  UrqTabs,
  // UrqTab,
  UrqImage,
  UrqSpacer,
  UrqBox,
  UrqButton,
  UrqText,
  UrqSelect,
} from '../index'

import type {
  UrqTabProps,
  UrqListItemProps,
} from '../index'
import {
  Link as RouterLink,
  LinkProps,
  useLocation,
} from 'react-router-dom'
import { useState } from 'react'
import { theme as globalTheme } from '../../../themes/theme'
import { getPathFromLabel } from '../../../utils/tools'

type UrqNavLink = {
  label: string,
  path: string,
}

type UrqLanguage = {
  id: string,
  name: string,
}

export type UrqNavbarProps = {
  children?: React.ReactNode
  urqNavLinks: UrqNavLink[]
  urqNavTitle?: React.ReactNode
  urqDrawerAnchor?: string
  urqLanguages?: UrqLanguage[] | false
  urqCompany: React.ReactNode
}

type UrqNavTitleProps = {
  navTitle: React.ReactNode
}

const LinkTab: React.ComponentType<UrqTabProps & LinkProps> = UrqTab as React.ComponentType<UrqTabProps & LinkProps>

const LinkListItem: React.ComponentType<UrqListItemProps & LinkProps> = UrqListItem as React.ComponentType<UrqListItemProps & LinkProps>

const UrqNavbar: React.FC<UrqNavbarProps> = ({
  children,
  urqNavLinks,
  urqNavTitle,
  urqDrawerAnchor,
  urqCompany,
  urqLanguages,
  ...rest
}) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const { i18n } = useTranslation()
  const { pathname } = useLocation()

  const screen670 = useMediaQuery('(max-width:670px)')
  const defaultLanguage = i18n.resolvedLanguage
  const muiTheme = useTheme()
  
  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value)
  }

  const handleToggleDrawer = () => {
    setIsOpenDrawer((isOpen) => !isOpen)
  }

  const ListTitle: React.FC<UrqNavTitleProps> = ({ navTitle }) => {
    return (
      <UrqBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LinkListItem
          component={RouterLink}
          urqType='button'
          sx={{
            paddingLeft: globalTheme.spaces.md1,
            display: 'flex',
            // justifyContent: 'center',
            margin: ' 8px 16px',
            borderRadius: '4px',
            fontFamily: globalTheme.fontFamilies.title,
            fontSize: globalTheme.fontSizes.md1
          }}
          to='/'
          onClick={() => handleToggleDrawer()}
        >
          { navTitle }
        </LinkListItem>
        {
          urqLanguages &&
          <UrqSelect
            urqOptions={urqLanguages}
            defaultValue={defaultLanguage}
            onChange={handleLanguageChange}
            sx={{
              display: {
                xs: 'flex',
                sm: 'none',
                md: 'none',
                lg: 'none',
              },    
            }}          
          />        
        }
        <UrqButton
          urqIcon='Close'
          onClick={() => handleToggleDrawer()}
          sx={{
            margin: '0 16px',
          }}
        />  
      </UrqBox>    
    )
  }  

  return (
    <AppBar {...rest}>
      <UrqToolbar
        sx={{
          background: muiTheme.palette.mode === 'dark' ? '' : globalTheme.colors.n0,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {/* Logo  */}
        <UrqBox
          component={RouterLink}
          sx={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
          }}
          to='/'
        >
          { urqCompany }
        </UrqBox>
 
        {/* Desktop  */}
        <UrqBox
          sx={{
            display: {
              xs: 'none',
              sm: !screen670 && 'flex',
              md: 'flex',
              lg: 'flex',
            },            
          }}
        >
          <UrqTabs
            value={pathname}
            indicatorColor='secondary'
            className='urq-max-width-complete'
            sx={{
              marginRight: globalTheme.spaces.md1
            }}     
          >
            {
              urqNavLinks?.map(({ label, path }, index) => (
                <LinkTab
                  key={label}
                  label={ label }
                  component={RouterLink}
                  to={path}
                  value={path}
                />          

              ))
            }
          </UrqTabs>
          {
            urqLanguages &&
            <UrqSelect
              urqOptions={urqLanguages}
              value={defaultLanguage}
              onChange={handleLanguageChange}
              sx={{
                display: {
                  xs: 'none',
                  sm: 'flex',
                  md: 'flex',
                  lg: 'flex',
                },    
              }}          
            />
          }
        </UrqBox>
        {/* Mobile  */}
        <UrqBox
          sx={{
            display: {
              xs: 'flex',
              sm:  !screen670 && 'none',
              md: 'none',
              lg: 'none',
            },    
          }}
        >
          <UrqButton
            urqIcon='Menu'
            sx={{
              marginLeft: globalTheme.spaces.sm
            }}
            onClick={() => setIsOpenDrawer(value => !value)}
          />
          <UrqDrawer
            open={isOpenDrawer}
            onClose={() => handleToggleDrawer()}
            anchor={urqDrawerAnchor ? urqDrawerAnchor : 'bottom'}
          >
            <UrqList
              urqListTitle={{
                element: urqCompany && <ListTitle navTitle={urqCompany} />,
                props: {
                  disableGutters: true
                }
              }}
            >
              {
                urqNavLinks.map(({label, path}) => (
                  <LinkListItem
                    key={label}
                    urqType='button'
                    to={path}
                    component={RouterLink}
                    onClick={() => handleToggleDrawer()}
                    value={pathname}
                    selected={pathname === `/${getPathFromLabel(label)}`}
                  >
                    <UrqListItem urqType='text'>
                      {label}
                    </UrqListItem>                    
                  </LinkListItem>                  
                ))
              }
            </UrqList>
          </UrqDrawer>
        </UrqBox>      
      </UrqToolbar>
      {children}
    </AppBar>
  )
}

export default UrqNavbar
