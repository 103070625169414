import { useEffect, useState } from 'react'
import { theme  as globalTheme } from '../../themes/theme'
import { useMediaQuery, useTheme } from '@mui/material'
import {
  UrqRow,
  UrqColumn,
  UrqBox,

} from '../../components/basecomponents'
import {
  Hero,
  Bullets,
  About,
  Contact
} from '../sections'

function Main() {
  const theme = useTheme()
  const [isDark, setIsDark] = useState(false)
  const [open, setOpen] = useState(false)

  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const toggleDarkMode = () => {
    setIsDark(() => !isDark)
  }
  
  useEffect(() => {
    if (isDark) {
      theme.palette.mode = 'dark'
    }
  }, [isDark])
  
  


  return (
    <UrqBox
      component='main'
      sx={{
        padding: smallScreen ? globalTheme.spaces.md1 : globalTheme.spaces.md2
      }}
    >
      <Hero />
    </UrqBox>
  );
}

export default Main
