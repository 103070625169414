import { Button, IconButton, ButtonProps as MuiButtonProps } from '@mui/material'
import UrqIcon, { UrqIconNames } from '../UrqIcon/UrqIcon';

export type UrqButtonProps = {
  children?: React.ReactNode
  urqIcon?: UrqIconNames
} & MuiButtonProps

const UrqButton: React.FC<UrqButtonProps> = ({
  children,
  urqIcon,
  ...rest
}) => {

  // Puedes acceder a las props adicionales de UrqButton y las props de Button de Material UI
  // Además, puedes acceder a onClick, onBlur y otros atributos de React directamente en rest

  return (
    !urqIcon ?
    <Button {...rest}>
      {children}
    </Button>
    :
    <IconButton
      {...rest}
    >
      <UrqIcon urqIconName={urqIcon} />
    </IconButton>
  );
};

export default UrqButton;
