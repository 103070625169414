import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import "./index.css";
import { ThemeConfig } from './themes/ThemeConfig'
import './styles/urqStyles.css'
import Main from './layout/Main/Main'
import { useRouteError, isRouteErrorResponse} from 'react-router-dom'
import './i18n'
import { getPathFromLabel } from './utils/tools'
import i18n from './i18n'
import {
  About,
  Services,
  OurTeam,
  Contact,
  TermsAndConditions,
  Privacy,
} from './layout/sections'

const ErrorPage = () => {
  const error = useRouteError()
  let errorMessage: string;

  if (isRouteErrorResponse(error)) {
    // error is type `ErrorResponse`
    errorMessage = error.error?.message || error.statusText;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  } else {
    console.error(error);
    errorMessage = 'Unknown error';
  }
  
  return (
    <>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{ errorMessage }</i>
      </p>
    </>
  )
}

const SubDomainError = () => {
  return (
    <h1>Oh no! Subdomain doesn't exist</h1>
  )
}

const tLink = i18n.getFixedT('en', null, 'header.links')

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Main />,
        errorElement: <ErrorPage />,
      },
      {
        path: `/${getPathFromLabel(tLink('about'))}`,
        element: <About />,
        errorElement: <ErrorPage />,
      },
      {
        path: `/${ getPathFromLabel(tLink('services')) }`,
        element: <Services />,
        errorElement: <ErrorPage />,
      },
      {
        path: `/${ getPathFromLabel(tLink('ourTeam')) }`,
        element: <OurTeam />,
        errorElement: <ErrorPage />,
      },
      {
        path: `/${ getPathFromLabel(tLink('contact')) }`,
        element: <Contact />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/terms-and-conditions',
        element: <TermsAndConditions />,
      },
      {
        path: '/privacy-policy',
        element: <Privacy />,
      },
      {
        path: '/sub-domain-error',
        element: <SubDomainError />,
      },
    ]
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <ThemeConfig>
        {/* <Header /> */}
        <RouterProvider router={router} />    
        {/* <Footer /> */}
      </ThemeConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
