import {
  UrqBox,
  UrqColumn,
  UrqRow,
  UrqText,
} from '../../../components/basecomponents'
import { useTranslation } from 'react-i18next'

import MemberImg01 from '../../../img/team/member-01.png'
import MemberImg02 from '../../../img/team/member-02.png'
import MemberImg03 from '../../../img/team/member-03.png'


import './OurTeam.css'
import {
  urqTheme as globalTheme,
  useSmallScreen,
  useLargeScreen,
  useXLargeScreen,
} from '../../../utils/tools'
import { useState } from 'react'
import Avatar from './Avatar/Avatar'

const OurTeam: React.FC = () => {
  const { t, i18n } = useTranslation()
  const tTeam = i18n.getFixedT(null, null, 'about.section_03')
  const xLargeScreen = useXLargeScreen()
  const largeScreen = useLargeScreen()
  const smallScreen = useSmallScreen()

  const [ members, setMembers ] = useState([
    {
      name: tTeam('founder_01.name'),
      position: tTeam('founder_01.position'),
      description: <>
        <UrqText
          variant='body1'
          className='urq-my-24'
        >
          {tTeam('founder_01.description')}
        </UrqText>
      </>,      
      img: {
        url: MemberImg01,
        alt: ''
      },
      expanded: false,
    },    {
      name: tTeam('founder_02.name'),
      position: tTeam('founder_02.position'),
      description: <>
        <UrqText
          variant='body1'
          className='urq-my-24'
        >
          {tTeam('founder_02.description')}
        </UrqText>
      </>,
      img: {
        url: MemberImg02,
        alt: ''
      },
      expanded: false
    },
    {
      name: tTeam('founder_03.name'),
      position: tTeam('founder_03.position'),
      description: <>
        <UrqText
          variant='body1'
          className='urq-my-24'
        >
          {tTeam('founder_03.description')}
        </UrqText>
      </>,
      img: {
        url: MemberImg03,
        alt: ''
      },
      expanded: false
    },
     
  ])

  const handleExpandClick = (index: number) => {
    setMembers((prevMembers) =>
      prevMembers.map((member, i) =>
        i === index ? { ...member, expanded: !member.expanded } : member
      )
    );
  }



  return (
    <UrqBox
      component='section'
      className='our-team-container'
      sx={{
        padding: smallScreen ? globalTheme.spaces.md1 : globalTheme.spaces.md2
      }}      
    >
      <UrqRow
        justifyContent='center'
        className='urq-mt-8'
      >
        <UrqColumn
          xl={9}
          lg={10}
          md={12}
          sm={12}
          xs={12}
          className='
            urq-flex
            urq-flex-column
            urq-justify-center
          '
        >
          <UrqText
            variant='h1'
            className='
              urq-my-24
            '
            sx={{
              textAlign: !xLargeScreen || !largeScreen ? 'center !important' : 'start !important'
            }}
          >
            { tTeam('title') }
          </UrqText>
        </UrqColumn>
        <UrqColumn
          xl={8}
          lg={10}
          md={12}
          sm={12}
          xs={12}
          gap={6}
          className='
            urq-flex
            urq-justify-center
            urq-flex-is-wrap
            urq-mt-32
          '
        >
          {
            members.map(({ name, position, description, img, expanded }, index) => (
                <Avatar
                  key={`member-${index}`}
                  img={img}
                  position={position}
                  name={name}
                  description={description}
                  isExpanded={expanded}
                  onClick={() => handleExpandClick(index)}
                />
            )) 
          }
        </UrqColumn>
      </UrqRow>      
    </UrqBox>
  );
}

export default OurTeam